import logo from "./logo.svg";
import Layout from "./component/layout";
import Logo from "./assets/Logo Utama (1).png";
import Vlepo from "./assets/vlepo.png";
import Keponet from "./assets/keponet.png";
import Megalos from "./assets/megalos.png";
import { Input, initTWE } from "tw-elements";
import Accordion from "./component/accordion";
import { useState } from "react";
import MultipleItems from "./component/carosel";
import Bg from "./assets/bg.jpg";
function App() {
  initTWE({ Input }, { allowReinits: true });
  const [id, setId] = useState("accordionVlepo");
  const [isOpen, setIsOpen] = useState({
    vlepo: true,
    megalos: false,
    keponet: false,
  });
  console.log(isOpen);

  return (
    <>
      <Layout>
        <div className=" relative overflow-hidden">
          <div
            className="absolute inset-0 z-0 "
            style={{
              backgroundImage: `url(${Bg})`,
              opacity: "0.1",
              zIndex: -1,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
          <div className="z-50 pt-7">
            <div className="py-5 pb-10 lg:pt-5 lg:pb-16">
              <div className="flex justify-center ">
                <img src={Logo} alt="Logo" className="w-2/4 md:w-325 md:h-1/3 lg:w-1/4 " />
              </div>
              <div className="flex justify-center">
                <div className="lg:w-1/2 md:w-3/5">
                  <div className="font-semibold text-[32px] text-center leading-9 mt-4 ">
                    <span className="block lg:inline md:inline ">
                      Ekosistem Digital{" "}
                    </span>
                    <span className="block md:inline  lg:inline">
                      untuk Digitalisasi{" "}
                    </span>
                    <span className="block lg:inline md:inline ">
                      dan Efisiensi Lintas{" "}
                    </span>
                    Industri.
                  </div>
                  <div className="flex justify-center mt-2">
                    <div className="text-center text-[18px] px-4 leading-5 lg:w-10/12 mt-2 ">
                      <span className="block lg:inline">
                        Varnion semakin maju dengan berbagai{" "}
                      </span>
                      SaaS dan platform pendukung bisnis Anda
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:px-8">
              <Accordion
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                id={id}
                setId={setId}
                classAcc={"bg-keponet rounded-lg m-4"}
                classBtnAcc={"rounded-lg"}
                nameShow={<img src={Keponet} alt="Keponet" />}
              />
              <div className="lg:flex justify-center md:px-4">
                <div className="my-8 p-4 lg:w-[90%]">
                  <div className="font-bold text-[32px] lg:text-[63px] leading-[38px] lg:leading-[70px] lg:w-[70%] xl:w-2/3">
                    <span className="block md:inline lg:inline xl:block">
                      Para Mitra dan{" "}
                    </span>
                    <span className="block lg:inline">Partner Kebanggan </span>
                    Varnion
                  </div>
                  <div className=" font-medium text-lg mt-4 text-[#767676] xs:w-full lg:w-1/2">
                    <span className="block lg:inline md:inline xs:inline ">
                      Lebih dari 600+ Mitra Bisnis{" "}
                    </span>
                    <span className="sm:block lg:inline md:inline ">
                      dan Usaha Telah{" "}
                    </span>
                    <span className="block lg:inline md:block xs:inline  ">
                      Dipercayakan pada Varnion{" "}
                    </span>
                    untuk Integrasi Internet dan Teknologi.
                  </div>
                  <MultipleItems />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default App;
