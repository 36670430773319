import React from "react";
import Slider from "react-slick";
import PartnerOne from "../assets/partner1.png";
import PartnerTwo from "../assets/partner2.png";
import PartnerThree from "../assets/partner3.png";
import PartnerFour from "../assets/partner4.png";
import PartnerFive from "../assets/partner5.png";
import PartnerSix from "../assets/partner6.png";
import PartnerSeven from "../assets/partner7.png";
import PartnerEight from "../assets/partner8.png";
import PartnerNine from "../assets/partner9.png";
import PartnerTen from "../assets/partner10.png";

function MultipleItems() {
  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: false,
    autoplay: true,
  };
  return (
    <>
      <div className=" mt-8 mb-4 ">
        <Slider {...settings}>
          <div className=" me-2 mx-auto ">
            <img
              src={PartnerOne}
              alt="Partner2"
              className="h-3/4 w-3/4  lg:w-1/2 lg:h-1/2 mx-auto"
            />
          </div>
          <div className="  me-2">
            <img
              src={PartnerTwo}
              alt="Partner2"
              className="h-3/4 w-3/4 lg:w-1/2 lg:h-1/2 mx-auto"
            />
          </div>
          <div className=" me-2">
            <img
              src={PartnerThree}
              alt="Partner3"
              className="h-3/4 w-3/4 lg:w-1/2 lg:h-1/2 mx-auto"
            />
          </div>
          <div className=" me-2">
            <img
              src={PartnerFour}
              alt="Partner4"
              className="h-3/4 w-3/4 lg:w-1/2 lg:h-1/2 mx-auto"
            />
          </div>
          <div className=" me-2">
            <img
              src={PartnerFive}
              alt="Partner5"
              className="h-3/4 w-3/4 lg:w-1/2 lg:h-1/2 mx-auto"
            />
          </div>
          <div className=" me-2">
            <img
              src={PartnerSix}
              alt="Partner6"
              className="h-3/4 w-3/4 lg:w-1/2 lg:h-1/2 mx-auto"
            />
          </div>
          <div className=" me-2">
            <img
              src={PartnerSeven}
              alt="Partner7"
              className="h-3/4 w-3/4 lg:w-1/2 lg:h-1/2 mx-auto"
            />
          </div>
          <div className=" me-2">
            <img
              src={PartnerEight}
              alt="Partner8"
              className="h-3/4 w-3/4 lg:w-1/2 lg:h-1/2 mx-auto"
            />
          </div>
          <div className=" me-2">
            <img
              src={PartnerNine}
              alt="Partner9"
              className="h-3/4 w-3/4 lg:w-1/2 lg:h-1/2 mx-auto"
            />
          </div>
          <div className=" me-2">
            <img
              src={PartnerTen}
              alt="Partner10"
              className="h-3/4 w-3/4 lg:w-1/2 lg:h-1/2 mx-auto"
            />
          </div>
        </Slider>
      </div>
    </>
  );
}

export default MultipleItems;
