import React, { useCallback, useEffect, useState } from "react";
import LogoMegalos from "../assets/Frame 1000007655.svg";
import { FaAngleDown } from "react-icons/fa";
import { Collapse, initTWE } from "tw-elements";
import Vlepo from "../assets/vlepo.png";
import Megalos from "../assets/megalos.png";
import Keponet from "../assets/keponet.png";
import VlepoMerge from "../assets/vlepo-merge.svg";
import LaptopMegalos from "../assets/laptop-mega.svg";
import KeponetMerge from "../assets/keponet-merge.svg";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import { AiOutlineBook } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-scroll";
function AccordionKeponet({
  id,
  setId,
  nameShow,
  content,
  classAcc,
  classBtnAcc,
  isOpen,
  setIsOpen,
}) {
  const [isShow, setIsShow] = useState({
    vlepo: false,
    megalos: true,
    keponet: true,
  });

  useEffect(() => {
    initTWE({ Collapse });
  }, []);
  const handleShow = (menu) => {
    setIsShow({
      vlepo: true,
      megalos: true,
      keponet: true,
      [menu]: !isShow[menu],
    });
    // window.scrollTo({
    //   top: 300,
    //   behavior: "smooth"
    // });
    // const targetY =
    //   document.getElementById("accordionFlushExample").getBoundingClientRect()
    //     .top + window.pageYOffset;
    // const startTime = performance.now();
    // const duration = 1000;

    // const scrollAnimation = (currentTime) => {
    //   const elapsedTime = currentTime - startTime;
    //   const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

    //   if (elapsedTime < duration) {
    //     window.scrollTo(0, targetY * easeInOutQuad(elapsedTime / duration));
    //     requestAnimationFrame(scrollAnimation);
    //   } else {
    //     window.scrollTo(0, targetY);
    //   }
    // };

    // requestAnimationFrame(scrollAnimation);
  };
  return (
    <div id="accordionFlushExample">
      <div className=" rounded-[30px] border-[1px] border-slate-400  my-3 mx-4 bg-vlepo  opacity-100 z-10">
        <h2 className="mb-0" id="flush-headingOne">
          <Link
            to="accordionFlushExample"
            spy={true}
            smooth={true}
            offset={-90}
            duration={300}
            className="group relative flex w-full items-center"
          >
            <button
              onClick={() => handleShow("vlepo")}
              className="group relative flex w-full items-center rounded-full bg-vlepo px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[5] focus:z-[5] focus:outline-none opacity-100"
              type="button"
              data-twe-collapse-init
              data-twe-target="#flush-collapseOne"
              aria-expanded="true"
              aria-controls="flush-collapseOne"
            >
              <img
                src={Vlepo}
                alt="Vlepo"
                className={`transition-opacity duration-300 ease-in-out ${
                  !isShow["vlepo"] ? "opacity-0" : ""
                }`}
              />
              <span className="-me-1 ms-auto h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                <IoChevronDownCircleOutline />
              </span>
            </button>
          </Link>
        </h2>
        <div
          id="flush-collapseOne"
          className="!visible border-0"
          data-twe-collapse-item
          data-twe-collapse-show
          aria-labelledby="flush-headingOne"
          data-twe-parent="#accordionFlushExample"
        >
          <div className="px-5 py-4 opacity-100 ">
            <div className="flex justify-center">
              <img
                src={VlepoMerge}
                alt="vlepomerge"
                className="md:w-2/3 md:h-1/2 md:mb-8"
              />
            </div>
            <div className="lg:grid grid-rows-5  grid-flow-col gap-4 lg:mx-8 lg:my-5">
              <div className="row-span-2 col-span-6 border rounded-3xl border-slate-400 p-4 mb-3 lg:px-8 lg:py-5">
                <img src={Vlepo} alt="vlepologo" className="" />
                {/* </div> */}
                <div className="font-bold text-[22px] leading-7 mt-3 lg:w-10/12">
                  <span className="block lg:inline xs:inline">
                    Aplikasi untuk Mengelola
                  </span>{" "}
                  <span className="block lg:inline xs:inline">
                    Semua Android TV dalam Satu{" "}
                  </span>
                  Platform.
                </div>
              </div>
              <div className="row-span-3 col-span-6  border rounded-3xl border-slate-400 p-4 mb-3 lg:px-8 lg:py-5">
                <div className="font-bold text-[18px] leading-5 flex justify-between items-center">
                  Apa itu Vlepo:
                  <AiOutlineBook className="text-black" size={24} />
                </div>
                <hr className="my-3 border-slate-400" />
                <div className="text-[18px] leading-6">
                  Jika Anda memiliki banyak aset properti seperti TV di area
                  bisnis Anda, Anda dapat menganalisis, mengorganisir, dan
                  memonetisasi penggunaan TV Anda untuk meningkatkan pendapatan
                  bisnis Anda.
                </div>
              </div>
              <div className="row-span-5 col-span-4  border rounded-3xl border-slate-400 p-4 mb-3 lg:px-8 lg:py-5 ">
                <div className="font-bold text-[18px] leading-5 flex justify-between items-center">
                  Daftar Fitur:
                  <FaCheckCircle className="text-black" size={24} />
                </div>
                <hr className="my-3 border-slate-400" />
                <div className="leading-6 ps-5 ">
                  <ul className="list-disc text-[18px] lg:flex justify-between">
                    <div className="lg:w-[44%]">
                      <li>
                        <b>
                          Multitenancy Dashboard :<br />
                        </b>
                        <span>
                          Dashboard tunggal yang memberikan Anda kontrol penuh
                          terhadap pengaturan konten termasuk promosi, pesan
                          maupun tayangan yang akan ditampilkan pada TV.
                        </span>
                      </li>
                      <li>
                        <b>Buat Pesan: </b>Tingkatkan interaksi dengan pesan
                        personal.
                      </li>
                    </div>
                    <div className="lg:w-[44%] lg:ms-4">
                      <li>
                        <b>Entertainment: </b> Nikmati aplikasi hiburan untuk
                        para tamu Anda.
                      </li>
                      <li>
                        <b className="lg:block">
                          Chromecast with Isolation Engine:{" "}
                        </b>{" "}
                        <span className="lg:mb-4">
                          Tampilkan konten dengan aman.
                        </span>
                      </li>
                      <li>
                        <b className="lg:block block">Promotions:</b>{" "}
                        <span className="lg:mb-4">
                          Promosikan penawaran saat ini.
                        </span>
                      </li>
                      <li>
                        <b className="lg:block block">
                          Broadcast Notification:
                        </b>
                        <span className="lg:mb-4">
                          Kirim pesan personal atau notif darurat kepada
                          pengguna.
                        </span>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-[30px] border-[1px] border-slate-400  my-3 mx-4 bg-megalos">
        <h2 className="mb-0" id="flush-headingTwo">
          <Link
            to="accordionFlushExample"
            spy={true}
            smooth={true}
            duration={300}
            offset={-15}
            className="group relative flex w-full items-center"
          >
            <button
              onClick={() => handleShow("megalos")}
              className="group relative flex w-full items-center rounded-full bg-megalos px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none "
              type="button"
              data-twe-collapse-init
              data-twe-collapse-collapsed
              data-twe-target="#flush-collapseTwo"
              aria-expanded="true"
              aria-controls="flush-collapseTwo"
            >
              <img
                src={Megalos}
                alt="megalos"
                className={`transition-opacity duration-300 ease-in-out ${
                  !isShow["megalos"] ? "opacity-0" : ""
                }`}
              />
              <span className="-me-1 ms-auto h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                <IoChevronDownCircleOutline />
              </span>
            </button>
          </Link>
        </h2>
        <div
          id="flush-collapseTwo"
          className="!visible hidden border-0"
          data-twe-collapse-item
          aria-labelledby="flush-headingTwo"
          data-twe-parent="#accordionFlushExample"
        >
          <div className="lg:flex lg:flex-col px-5 py-4 md:py-1 ">
            <div className="flex justify-center lg:order-2  ">
              <img
                src={LaptopMegalos}
                alt="laptopmegalos"
                className="sm:w-2/3 lg:w-auto md:w-2/3 md:h-1/2 md:mb-8 mb-4  "
              />
            </div>
            <div className="lg:order-first lg:mx-8 lg:my-5">
              <div className=" lg:grid grid-rows-2 grid-flow-col gap-4 ">
                <div className="row-span-1 col-span-12 border rounded-3xl border-slate-400 p-4 mb-3 text-center ">
                  <div className="flex lg:justify-center ">
                    <img
                      src={LogoMegalos}
                      alt="megalos"
                      className=" w-44 lg:w-auto "
                    />
                  </div>
                  <div className="font-bold text-start lg:text-center text-[18px] lg:text-[32px] leading-6 lg:leading-9 mt-3">
                    <span className="lg:block">
                      Maksimalkan Bandwidth yang Anda dapatkan Secara efektif
                      dan{" "}
                    </span>
                    Efisien dengan Sistem Manajemen Bandwidth Cerdas
                  </div>
                </div>
                <div className="row-span-1 col-span-6 border rounded-3xl border-slate-400 p-4 mb-3 lg:px-8">
                  <div className="font-bold text-[18px] leading-5 flex justify-between items-center">
                    Apa itu Megalos:
                    <AiOutlineBook className="text-black" size={24} />
                  </div>
                  <hr className="my-3 border-slate-400" />
                  <div className="text-[18px] leading-6">
                    <span className="lg:block">
                      {" "}
                      <span className="block lg:inline">
                        Platform untuk Mengelola Dinamika
                      </span>{" "}
                      Trafik dengan
                    </span>{" "}
                    <span className="lg:block ">
                      Sistem Manajemen Pengguna Internet dengan
                    </span>{" "}
                    <span className="">Efektif dan Mudah.</span>
                  </div>
                </div>
                <div className="row-span-1 col-span-6 border rounded-3xl border-slate-400 p-4 mb-3 lg:px-8">
                  <div className="font-bold text-[18px] leading-5 flex justify-between items-center">
                    Daftar Fitur:
                    <FaCheckCircle className="text-black" size={24} />
                  </div>
                  <hr className="my-3 border-slate-400" />
                  <div className="leading-6 ps-5">
                    <ul className="list-disc text-[18px] ">
                      <li>Pemantauan Bandwidth Real Time</li>
                      <li>Manajemen Statistik Perangkat</li>
                      <li>Manajemen Bandwidth</li>
                      <li>Membuat Halaman Login untuk Bisnis Anda</li>
                      <li>Pengguna Tanpa Batas</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" rounded-[30px] border-[1px] border-slate-400  my-3 mx-4 bg-keponet">
        <h2 className="mb-0" id="flush-headingThree">
          <Link
            to="accordionFlushExample"
            spy={true}
            smooth={true}
            duration={300}
            offset={55}

            className="group relative flex w-full items-center"
          >
            <button
              onClick={() => handleShow("keponet")}
              className="group relative flex w-full items-center rounded-full bg-keponet px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none "
              type="button"
              data-twe-collapse-init
              data-twe-collapse-collapsed
              data-twe-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              <img
                src={Keponet}
                alt="keponet"
                className={`transition-opacity duration-300  w-3/12 md:w-[16%] lg:w-1/12 xl:w-1/12 2xl:w-[5%] xs:w-[30%] ease-in-out ${
                  !isShow["keponet"] ? "opacity-0" : ""
                }`}
              />
              <span className="-me-1 ms-auto h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                <IoChevronDownCircleOutline />
              </span>
            </button>
          </Link>
        </h2>
        <div
          id="flush-collapseThree"
          className="!visible hidden"
          data-twe-collapse-item
          aria-labelledby="flush-headingThree"
          data-twe-parent="#accordionFlushExample"
        >
          <div className="lg:flex lg:flex-col px-5 py-4">
            <div className="flex justify-center lg:order-2">
              <img
                src={KeponetMerge}
                alt="keponet-merge"
                className="w-2/3 lg:w-auto md:w-2/3 md:h-1/2 md:mb-8 mb-4"
              />
            </div>
            <div className="lg:grid grid-cols-4 grid-rows-5 gap-4 lg:mx-8 lg:my-5 lg:order-1">
              <div className="col-span-2 border rounded-3xl border-slate-400 p-4 mb-3 row-span-2 lg:px-8">
                <img src={Keponet} alt="keponet" className="" />
                {/* </div> */}
                <div className="font-bold text-[24px] lg:text-[32px] leading-6 lg:leading-[38px] mt-3 ">
                  <span className="lg:block">
                    Aplikasi cerdas untuk membantu
                  </span>{" "}
                  <span className="lg:block">Anda menghadirkan layanan </span>
                  terbaik
                </div>
              </div>
              <div className="col-span-2 border rounded-3xl border-slate-400 p-4 mb-3 row-span-2 lg:px-8">
                <div className="font-bold text-[18px] leading-5 flex justify-between items-center">
                  Apa itu Keponet:
                  <AiOutlineBook className="text-black" size={24} />
                </div>
                <hr className="my-3 border-slate-400" />
                <div className="text-[18px] leading-6">
                  <span className="lg:block">
                    Inilah sebuah inovasi yang mengelola setiap permintaan
                  </span>{" "}
                  user secara cerdas melalui fitur mutakhir:
                </div>
              </div>
              <div className="border rounded-3xl border-slate-400 p-4 mb-3 col-span-4 row-span-3 lg:px-8">
                {" "}
                <div className="font-bold text-[18px] leading-5 flex justify-between items-center">
                  Daftar Fitur:{" "}
                  <FaCheckCircle className="text-black" size={24} />
                </div>
                <hr className="my-3 border-slate-400" />
                <div className="leading-6 ps-4">
                  <ul className="list-disc text-[18px] lg:flex justify-between">
                    <div className="ps-1">
                      <li>
                        <b>
                          Eskalasi Otomatis: <br />
                        </b>
                        <span className="lg:block">
                          Pengawasan dan eskalasi real-time
                        </span>{" "}
                        <span className="lg:block">
                          untuk menangani permintaan pengguna
                        </span>{" "}
                        dengan baik.
                      </li>
                      <li>
                        <b>
                          Kepolytics:
                          <br />
                        </b>
                        <span className="lg:block">
                          Analisis kinerja staf dan permintaan
                        </span>{" "}
                        pengguna dengan data yang diperlukan.
                      </li>
                    </div>
                    <div>
                      <li>
                        <b>
                          Kolaborasi:
                          <br />
                        </b>
                        <span className="lg:block">
                          Kolaborasi mudah dengan staf dari
                        </span>{" "}
                        <span className="lg:block">
                          berbagai departemen untuk
                        </span>{" "}
                        menyelesaikan permintaan pengguna.
                      </li>
                      <li>
                        <b>
                          Chat in Task: <br />
                        </b>
                        <span className="lg:block">
                          {" "}
                          Diskusikan pekerjaan tanpa intervensi
                        </span>{" "}
                        <span className="lg:block">
                          staf lain; chat ditutup otomatis setelah
                        </span>{" "}
                        permintaan pengguna terpenuhi.
                      </li>
                    </div>
                    <div>
                      <li>
                        <b>
                          Barcode Scan: <br />
                        </b>
                        <span className="lg:block">
                          User dapat langsung menyampaikan
                        </span>{" "}
                        <span className="lg:block">
                          permintaan melalui aplikasi yang mudah
                        </span>{" "}
                        digunakan dengan pemindaian barcode.
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccordionKeponet;
